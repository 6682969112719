<template>
  <div class="step text-18-800" :class="{'__active': step.isCurrent, '__with-margin': step.hasLine, '__done': step.isDone}">
    <div class="step-counter">
      <svg-icon
        v-if="step.isDone"
        icon-name="checkmark"
        class-name="step-icon"
      />
      <template v-else>
        {{ step.id }}
      </template>
    </div>
    <div class="step-title">{{ step.name }}</div>
    <div class="step-line" v-if="step.hasLine"></div>
  </div>
</template>

<script>

export default {
  name: 'StepItem',
  props: [
    'step',
  ],
}
</script>

<style lang="scss" scoped>
  $margin: 12px;
  $border-color: #8D959C;

  .step {
    display: flex;
    align-items: center;
    position: relative;

    &.__with-margin {
      margin-bottom: $margin;
    }

    &-counter {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 12px;
      border: 1px solid $border-color;
      color: $border-color;
      line-height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.__active &-counter {
      border: 1px solid $color-primary;
      color: $color-primary;
    }

    &.__active &-counter,
    &.__done &-counter {
      background-color: $color-primary;
      color: #fff;
    }

    &-title {
      color: $border-color;
    }

    &.__active &-title {
      color: $text-black;
    }

    &-line {
      width: 1px;
      height: $margin;
      background-color: $color-primary;
      position: absolute;
      top: 100%;
      left: 20px;
    }

    &-icon {
      fill: transparent;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
</style>
