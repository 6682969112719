<template>
  <div class="steps-container">
    <StepItem
      v-for="step in steps"
      :key="step.id"
      :step="step"
    />
  </div>
</template>

<script>
import StepItem from './StepItem'

export default {
  name: 'StepsContainer',
  components: { StepItem },
  props: [
    'steps',
  ],
}
</script>

<style lang="scss" scoped>
  .steps-container {
    box-sizing: border-box;
    padding: 29px 20px;
    border-radius: 20px;
    border: 1px solid $color-line;
    box-shadow: 0 0 24px 0 #0000000F;
  }
</style>
